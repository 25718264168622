// Funkce na smazání všech databází IndexedDB s řešením zavřených instancí
export default async function deleteAllDatabases() {
    console.log("mazání")
    if (!indexedDB.databases) {
        console.warn("Metoda indexedDB.databases() není podporována ve vašem prohlížeči.");
        return;
    }

    const databases = await indexedDB.databases();

    for (const db of databases) {
        console.log(`Pokus o zavření a smazání databáze: ${db.name}`);

        const deleteRequest = indexedDB.deleteDatabase(db.name);

        deleteRequest.onsuccess = () => {
            console.log(`Databáze "${db.name}" byla úspěšně smazána.`);
        };

        deleteRequest.onerror = (event) => {
            console.error(`Chyba při mazání databáze "${db.name}":`, event.target.error);
        };

        deleteRequest.onblocked = () => {
            console.warn(`Mazání databáze "${db.name}" je blokováno. Pokusím se zavřít otevřené instance.`);
            closeOpenInstances(db.name);
        };
    }
}

// Zavře všechny otevřené instance databáze
function closeOpenInstances(dbName) {
    const request = indexedDB.open(dbName);
    
    request.onsuccess = (event) => {
        const db = event.target.result;
        console.log(`Zavírám otevřenou instanci databáze "${dbName}".`);
        db.close();

        // Znovu zkusíme smazat databázi
        const deleteRequest = indexedDB.deleteDatabase(dbName);

        deleteRequest.onsuccess = () => {
            console.log(`Databáze "${dbName}" byla úspěšně smazána po zavření všech instancí.`);
        };

        deleteRequest.onerror = (event) => {
            console.error(`Chyba při opětovném mazání databáze "${dbName}":`, event.target.error);
        };

        deleteRequest.onblocked = () => {
            console.warn(`Mazání databáze "${dbName}" je stále blokováno.`);
        };
    };

    request.onerror = (event) => {
        console.error(`Nepodařilo se otevřít databázi "${dbName}" pro zavření:`, event.target.error);
    };
}