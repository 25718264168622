import React, { useContext, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import axiosInstance, { clearUserDetailDB } from "../api/AxiosConfig";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import deleteAllDatabases from "../utils/functions/deleteAllDatabases";


const useUserRegisterDetails = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation().pathname;
    const { lng } = useParams();

    const {
        uuid,
        setIsUserDataLoading,
        userData,
        setUserData,
        setUuid,
        selectedShop,
        setSelectedShop,
        setIsAdmin,
        setShowPNOWarning
    } = useContext(AppContext);

    // Odstranění cookies pro 404
    Cookies.remove("notfound");

    useEffect(() => {
        setShowPNOWarning(false)
        // Kontrola, zda jsou cookies přítomny
        const refreshToken = Cookies.get("rTkn_4zF7P");

        if (refreshToken) {
            fetchUserData();
        } else {
            // Pokud cookies nejsou přítomny, můžete provést nějakou další akci, např. navigaci na přihlašovací stránku
            navigate(`/${lng}/login/`);
        }
    }, [pathname]);

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            // setIsUserDataLoading(false);

            if (userData?.email === "info@permoniq.com") {
                setIsAdmin(true)
            }

            if (!userData.shops || Object.keys(userData.shops).length === 0) {
                setUuid("");
                setSelectedShop("")
                navigate(`/${lng}/app/register-shop/`);
            } else if (Object.keys(userData.shops).length === 1 && userData.shops.find(oneShop => oneShop.status_create === "end")) {
                navigate(`/${lng}/app/register-shop/`);

                if (!userData?.email === "info@permoniq.com") {
                    setUuid("");
                    setSelectedShop("")
                }
            } else {
                if (userData?.email === "info@permoniq.com") {
                    // let status = selectedShop.status_create


                    if (sessionStorage.getItem("x23uU09I098D")) {
                        let selectedShop = userData.shops.find(oneShop => oneShop.uuid === sessionStorage.getItem("x23uU09I098D"))
                        setUuid(sessionStorage.getItem("x23uU09I098D"))
                        setSelectedShop(selectedShop)
                    } else if (uuid) {
                        let selectedShop = userData.shops.find(oneShop => oneShop.uuid === uuid)

                        setSelectedShop(selectedShop)
                    } else {
                        console.log("not uuid")
                    }


                }
                else {
                    setUuid("")

                    const shopInStep1 = userData.shops.find(oneShop => oneShop.status_create === "step1");
                    const shopInBillInfo = userData.shops.find(oneShop => oneShop.status_create === "billinfo");
                    const shopInShopData = userData.shops.find(oneShop => oneShop.status_create === "shopdata");
                    const shopInAccount = userData.shops.find(oneShop => oneShop.status_create === "account");
                    const shopInPixel = userData.shops.find(oneShop => oneShop.status_create === "pixel");



                    if (shopInStep1) {
                        setSelectedShop(shopInStep1)
                        setUuid(shopInStep1.uuid);
                        navigate(`/${lng}/app/register-shop/`);
                    } else if (shopInBillInfo) {
                        setSelectedShop(shopInBillInfo)
                        setUuid(shopInBillInfo.uuid);
                        navigate(`/${lng}/app/company-details/`);
                    } else if (shopInShopData) {
                        setSelectedShop(shopInShopData)
                        setUuid(shopInShopData.uuid);
                        navigate(`/${lng}/app/upload-products/`);
                    } else if (shopInAccount) {
                        setSelectedShop(shopInAccount)
                        setUuid(shopInAccount.uuid);
                        navigate(`/${lng}/app/connect-google/`);
                    } else if (shopInPixel) {
                        setSelectedShop(shopInPixel)
                        setUuid(shopInPixel.uuid);
                        navigate(`/${lng}/app/install-tracking-code/`);
                    } else {
                        setSelectedShop("")
                        setUuid("")
                        navigate(`/${lng}/app/register-shop/`);
                    }
                }
            }
        }

        if (!window.location.pathname.includes("connect-google")) {
            setIsUserDataLoading(false);
        }

    }, [userData]);

    const fetchUserData = async () => {
        // setIsUserDataLoading(true);
        try {
            const response = await axiosInstance.get('user/detail/');
            setUserData(response.data);

            if (response?.data?.language !== Cookies.get("i18next")) {
                if (Cookies.get("i18next") === "cs-CZ") {
                    const updateResponse = await axiosInstance.patch(`/user/update/`, {
                        language: "cs"
                    })
                } else {
                    const updateResponse = await axiosInstance.patch(`/user/update/`, {
                        language: Cookies.get("i18next")
                    })
                }

            }
        } catch (error) {
            if (error?.response?.data?.code === "user_not_found") {
                alert("Tento uživatel neexistuje!")
                setIsUserDataLoading(true)
                Cookies.remove("rTkn_4zF7P")
                Cookies.remove("aTkn_0yD9K")
                Cookies.remove("utk_98x76")
                clearUserDetailDB()
                deleteAllDatabases()
            } else {
                console.error('Error fetching user details:', error);
            }
        }
    };
};

export default useUserRegisterDetails;
