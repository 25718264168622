import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import Cookies from 'js-cookie';

import { AppContext } from '../context/AppContext';

import axiosInstance from '../api/AxiosConfig';

const useUserAppDetails = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation().pathname;
    const { lng } = useParams();

    const {
        userData,
        setUserData,
        uuid,
        setUuid,
        selectedShop,
        setSelectedShop,
        setCampaignId,
        setBudget,
        setPno,
        setIsAdmin,
        setShowPNOWarning,
        pno,
        budget,
        setIsUserDataLoading
    } = useContext(AppContext);

    useEffect(() => {
        // Kontrola, zda jsou cookies přítomny
        const refreshToken = Cookies.get("rTkn_4zF7P");

        if (refreshToken) {
            fetchUserData();
        }
    }, []);

    // useEffect(() => {
    //     if (uuid) {
    //         fetchShopData(uuid)
    //     }
    // }, [uuid])

    useEffect(() => {
        fetchUserData();
    }, [pno, budget])

    useEffect(() => {
        if (sessionStorage.getItem("x23uU09I098D")) {

            setUuid(sessionStorage.getItem("x23uU09I098D"))

            if (Object.keys(userData).length > 0 && Object.keys(userData?.shops).length > 0) {
                let selectedShop = userData?.shops.find(oneShop => oneShop.uuid === sessionStorage.getItem("x23uU09I098D"))

                setSelectedShop(selectedShop)
            }
        } else {
            if (Object.keys(userData).length > 0 && Object.keys(userData?.shops).length > 0) {
                setSelectedShop(userData?.shops[0])
            }
        }

        if (selectedShop) {
            setUuid(selectedShop.uuid);
            setCampaignId(selectedShop.campaign?.find(oneCampaign => oneCampaign.campaign_type === "google-pmax")?.campaign_id);
            setBudget(selectedShop?.budget)
            setPno(selectedShop?.pno)
        }
        
    }, [userData, selectedShop])

    useEffect(() => {
        if (selectedShop) {
            if (budget === 0 || pno === 0) {
                setShowPNOWarning(true)
            } else {
                setShowPNOWarning(false)
            }
        }
    }, [pno, budget, selectedShop])

    const fetchUserData = async () => {
        // setIsUserDataLoading(true)
        try {
            const response = await axiosInstance.get('user/detail/');

            if (response?.data?.email === "info@permoniq.com") {
                setIsAdmin(true)
            }

            if (Object.keys(response?.data?.shops).length === 1 && response?.data?.shops[0]?.status_create !== "end") {
                navigate(`/${lng}/app/register-shop/`)
            } else if (Object.keys(response?.data?.shops).length === 0) {
                navigate(`/${lng}/app/register-shop/`)
            }
            setUserData(response.data);

            if (response?.data?.language !== Cookies.get("i18next")) {
                if (Cookies.get("i18next") === "cs-CZ") {
                    const updateResponse = await axiosInstance.patch(`/user/update/`, {
                        language: "cs"
                    })
                } else {
                    const updateResponse = await axiosInstance.patch(`/user/update/`, {
                        language: Cookies.get("i18next")
                    })
                }
            }

        } catch (error) {
            console.error('Error fetching user details:', error);
            throw error;
        } finally {
            setTimeout(() => {
                setIsUserDataLoading(false)
            }, 500);
        }
    };

    // const fetchShopData = async (uuid) => {
    //     try {
    //         const shopDataResponse = await axiosInstance.get(`shop/detail/${uuid}/`)
    //     } catch (error) {
    //         console.log("Fetching shop data error", error)
    //     }
    // }
};

export default useUserAppDetails;
