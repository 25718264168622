import React, { useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const {
    setIsUserDataLoading
  } = useContext(AppContext)

  useEffect(() => {
    setIsUserDataLoading(false)
  }, [setIsUserDataLoading])

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>PermoniQ = online reklama na jeden kliQ</title>
      </Helmet>
      <div className="privacy-policy">
        <h2>{t("privacyPolicy.title")}</h2>
        <ul>
          {t("privacyPolicy.items", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
          <li>
            <Trans
              i18nKey="privacyPolicy.linkItem"
              components={[
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("privacyPolicy.linkText")}
                </a>
              ]}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default PrivacyPolicy;
