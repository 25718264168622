import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { AppContext } from '../context/AppContext';

import permoniqLogo from "../img/logo-inverse.svg";
import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const NavbarLogin = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const languageRef = useRef(null);

  const {
    isAdmin,
    selectedShop,
    isLogged,
    appContentType
  } = useContext(AppContext);

  const handleLanguageChange = (language) => {
    const currentPath = location.pathname.replace(`/${lng}`, `/${language}`);
    i18n.changeLanguage(language.toLowerCase());
    navigate(currentPath);
    setIsLanguageSwitchVisible(false);
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const handleClickOutside = (event) => {
    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDetails = () => {
    setIsDetailsOpen((prev) => !prev);
  };

  // Rekurzivní funkce na vykreslení dat
  const renderShopData = (data, level = 0) => {
    return Object.entries(data).map(([key, value]) => {
      if (Array.isArray(value)) {
        return (
          <div key={key} style={{ marginLeft: `${level * 20}px` }}>
            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
            <ul>
              {value.map((item, index) => (
                <li key={index}>
                  {typeof item === 'object' ? renderShopData(item, level + 1) : item}
                </li>
              ))}
            </ul>
          </div>
        );
      } else if (typeof value === 'object' && value !== null) {
        return (
          <div key={key} style={{ marginLeft: `${level * 20}px` }}>
            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
            {renderShopData(value, level + 1)}
          </div>
        );
      } else {
        return (
          <p key={key} style={{ marginLeft: `${level * 20}px` }}>
            <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
          </p>
        );
      }
    });
  };

  return (
    <div className="container" data-navbar="login">
      <div className="header__logo">
        <a href={`${window.location.origin}/${lng}/`} title={t('loginNav.home')}>
          <img src={permoniqLogo} alt="PermoniQ" width="151" height="32" />
        </a>
      </div>
      <nav className="app-nav-header">
        {
          isAdmin && selectedShop && Object.keys(selectedShop).length > 0 && (
            <>
              <details open={isDetailsOpen} style={{ cursor: "pointer" }}>
                <summary onClick={toggleDetails}>
                  {selectedShop.name || selectedShop.website}
                </summary>
              </details>
              {isDetailsOpen && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(0, 0, 0)',
                    color: '#fff',
                    overflowY: 'auto',
                    padding: '20px',
                    zIndex: 1000,
                  }}
                >
                  <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                    <h2>{selectedShop.name}</h2>
                    {renderShopData(selectedShop)}
                    <button onClick={toggleDetails} style={{ marginTop: '20px', padding: '10px', background: '#fff', color: '#000', border: 'none', cursor: 'pointer' }}>
                      {t('Close')}
                    </button>
                  </div>
                </div>
              )}
            </>
          )
        }
        {/* <div className="language" ref={languageRef}>
          <a href="" onClick={toggleLanguageSwitch}>
            <img src={flags[lng.toLowerCase()]} alt={t(`loginLanguages.${lng.toLowerCase()}`)} />
          </a>
          <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
            {Object.keys(flags).map((lang) => (
              <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                <img src={flags[lang.toLowerCase()]} alt={t(`loginLanguages.${lang.toLowerCase()}`)} /> {t(`loginLanguages.${lang.toLowerCase()}`)}
              </a>
            ))}
          </div>
        </div> */}
      </nav>
    </div>
  );
};

export default NavbarLogin;
