import { useContext, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { ReactSVG } from "react-svg";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import Cookies from "js-cookie";

import { AppContext } from "../context/AppContext";

import NotFoundStyles from "../css/NotFoundStyles.module.css"

import notFoundImage from "../img/404_not_found.svg"

const NotFound = () => {
  const { t } = useTranslation();
  const { lng } = useParams();

  const {
    setIsUserDataLoading
  } = useContext(AppContext)

  useEffect(() => {
    setIsUserDataLoading(false)
    Cookies.set("notfound", true, { path: '/' }); // Nastavení cookie v useEffect
  }, [setIsUserDataLoading]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Pátrací akce č.404! - PermoniQ</title>
      </Helmet>
      <div className={`${NotFoundStyles.not_found_container}`} dat-navbar="not_found">
        <ReactSVG
          src={notFoundImage}
          className={`${NotFoundStyles.svg}`}
          beforeInjection={(svg) => {
            // svg.setAttribute("width", "100%");
            svg.setAttribute("height", "auto");
          }}
        />
        <h1 className={`${NotFoundStyles.heading}`}>{t('notFound.title')}</h1>
        <p className={NotFoundStyles.text}>{t('notFound.message')}</p>
        <p className={NotFoundStyles.text}>
          <Trans
            i18nKey={t('notFound.backToMainPageText')}
            components={[
              <a className={NotFoundStyles.anchor} href={`https://www.permoniq.com/${lng}/`}>{t('notFound.backToMainPageText')}</a>
            ]}
          />
        </p>
        <a href={`https://www.permoniq.com/${lng}/`} className="btn" style={{ marginTop: "40px" }}>{t('notFound.backToMainPageBtn')}</a>
      </div>
    </>
  );
};

export default NotFound;
