import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { checkDomainStatus } from '../utils/fetch_functions/checkDomainStatus';
import { cleanDomain } from '../utils/functions/cleanDomain';
import { sendErrorMessage } from '../utils/axios_functions/sendErrorMessage';

// import useRegisterUserDetails from '../hooks/useRegisterUserDetails';
import { AppContext } from '../context/AppContext';

import SimpleAlert from '../components/SimpleAlert';
import ProgressTracker from '../components/ProgressTracker';

import axiosInstance from '../api/AxiosConfig';

import useUserRegisterDetails from '../hooks/useUserRegisterDetails';

import ModalContactUs from '../components/ModalContactUs';
import ModalLoading from '../components/ModalLoading';

// import csFlag from "../img/flags/cs.png";
// import enFlag from "../img/flags/en.png";
// import skFlag from "../img/flags/sk.png";
// import plFlag from "../img/flags/pl.png";
// import huFlag from "../img/flags/hu.png";
// import roFlag from "../img/flags/ro.png";

// const flags = {
//     cs: csFlag,
//     en: enFlag,
//     sk: skFlag,
//     pl: plFlag,
//     hu: huFlag,
//     ro: roFlag,
// };

const phonePrefixes = {
    cs: '+420',
    sk: '+421',
    pl: '+48',
    hu: '+36',
    ro: '+40'
};

// const limitsByCountry = {
//     cs: { minLimit: 100, maxLimit: 20000 },
//     sk: { minLimit: 5, maxLimit: 800 },
//     hu: { minLimit: 2000, maxLimit: 800000 },
//     pl: { minLimit: 20, maxLimit: 3200 },
//     ro: { minLimit: 20, maxLimit: 3200 },
//     default: { minLimit: 100, maxLimit: 20000 },
// };

const RegisterShop = () => {
    // const [shopForRegistration, setShopForRegistration] = useState([])
    const [formData, setFormData] = useState({});
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    // const [phoneError, setPhoneError] = useState('');
    const [isCountrySelectOpen, setIsCountrySelectOpen] = useState(false);
    const [country, setCountry] = useState('cs');
    // const [borderColor, setBorderColor] = useState("");
    // const textareaRef = useRef(null);
    // const [charCount, setCharCount] = useState(0);
    // const maxLength = 10000;
    const [message, setMessage] = useState("")

    const {
        userData,
        uuid,
        setUuid,
        isAdmin,
        isAlertVisible,
        showAlert,
        closeAlert,
        alertMessage,
        steps,
        selectedShop,
        isUserDataLoading,
        setIsUserDataLoading
    } = useContext(AppContext);

    const { t } = useTranslation();
    const { lng } = useParams();
    const navigate = useNavigate();

    useUserRegisterDetails()

    window.dataLayer = window.dataLayer || []

    // useEffect(() => {
    //     // Aktualizace limitů na základě vybrané země
    //     const newLimits = limitsByCountry[country] || limitsByCountry.default;
    //     setLimits(newLimits);
    // }, [country]); // Spustí se pokaždé, když se změní země


    useEffect(() => {
        if (selectedShop && selectedShop.country) {
            setCountry(selectedShop.country)
        }
    }, [selectedShop])

    const toggleCountry = useCallback(() => {
        setIsCountrySelectOpen(!isCountrySelectOpen);
    }, [isCountrySelectOpen]);

    useEffect(() => {
        if (isCountrySelectOpen) {
            const handleKeyEvent = (e) => {
                if (e.key === "Escape") {
                    toggleCountry();
                }
            };

            const handleClickOutside = (e) => {
                if (!e.target.closest('.country')) {
                    setIsCountrySelectOpen(false);
                }
            };

            window.addEventListener("keydown", handleKeyEvent);
            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                window.removeEventListener("keydown", handleKeyEvent);
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isCountrySelectOpen, toggleCountry]);

    useEffect(() => {
        if (Object.keys(userData).length > 0 && Object.keys(userData.shops).length > 0) {
            if (isAdmin) {
                if (uuid) {
                    // setShopForRegistration(userData.shops.find(oneShop => oneShop.uuid === uuid))

                    // const shopInStep1 = userData.shops.find(oneShop => oneShop.uuid === uuid);

                    const { website, phone, first_name, last_name, country, description, pno, budget } = selectedShop ?? {};
                    setFormData({
                        website,
                        phone,
                        first_name,
                        last_name,
                        country,
                        description,
                        pno,
                        budget
                    });
                }
            } else {
                // if (uuid) {
                //     setShopForRegistration(userData.shops.find(oneShop => oneShop.uuid === uuid))
                // }

                // Najděte obchod, který má status_create rovný "step1"
                const shopInStep1 = userData.shops.find(shop => shop.status_create === "step1");

                if (shopInStep1 && Object.keys(shopInStep1).length > 0) {
                    const { website, phone, first_name, last_name, country, description, pno, budget } = shopInStep1;
                    setFormData({
                        website,
                        phone,
                        first_name,
                        last_name,
                        country,
                        description,
                        pno,
                        budget
                    });
                }
            }
        }
    }, [userData, isAdmin, selectedShop, uuid]);

    const isIpAddress = (hostname) => {
        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipRegex.test(hostname);
    };

    const validateAndCleanUrl = (url) => {
        try {
            if (!url.startsWith("http")) {
                url = `https://${url}`;
            }
            const urlObj = new URL(url);
            if (isIpAddress(urlObj.hostname)) {
                return false;
            }
            if (urlObj.hostname.includes('shoptet') || urlObj.hostname.includes('upgates')) {
                return false;
            }
            const hostnameParts = urlObj.hostname.split('.');
            if (hostnameParts.length >= 2) {
                return `https://${hostnameParts.join('.')}`;
            }
        } catch (error) {
            return false;
        }
        return false;
    };

    const handleInputChange = (key) => (e) => {
        const { value } = e.target;

        // Pokud se jedná o změnu v popisu, aktualizuj charCount a borderColor
        // if (key === "description") {
        //     setCharCount(value.length);
        //     if (value.length < 350) {
        //         setBorderColor("red");
        //     } else {
        //         setBorderColor("green");
        //     }
        // }

        setFormData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };



    const handleUrlBlur = () => {
        if (formData.website) {
            const cleanedUrl = validateAndCleanUrl(formData.website);
            if (cleanedUrl) {
                setIsUrlValid(true);
                setFormData(prevState => ({
                    ...prevState,
                    website: cleanedUrl
                }));
            } else {
                setIsUrlValid(false);
            }
        } else {
            setIsUrlValid(true);
        }
    };

    const handleCountryChange = (lang) => {
        setCountry(lang);
        setIsCountrySelectOpen(false); // Close the dropdown after selecting a country
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowErrors(true);
        setIsUserDataLoading(true)

        try {
            let cleanedUrl = cleanDomain(formData.website);

            const checkUrlResponse = await checkDomainStatus(cleanedUrl);
            const checkUrlResponseJSON = await checkUrlResponse.json();

            if (checkUrlResponseJSON.available === true) {
                showAlert(t("registerForm.errorMessages.domainNotExistErrorMsg"), "danger");
                setIsUserDataLoading(false)
                return;
            }

            let phone = formData.phone.replace(/\s+/g, '');
            const selectedPrefix = phonePrefixes[country];

            if (phone.startsWith(selectedPrefix)) {
                if (!phone.startsWith('+')) {
                    phone = `+${phone}`;
                }
            } else if (phone.startsWith(selectedPrefix.slice(1))) {
                phone = `+${phone}`;
            } else if (!phone.startsWith('+')) {
                phone = `${selectedPrefix}${phone}`;
            }

            const isPhoneValid = /^\+\d+$/.test(phone);

            if (formData.website && isPhoneValid && formData.first_name && formData.last_name && isUrlValid) {
                const determineCurrency = (country) => {
                    switch (country) {
                        case "cs": return "CZK";
                        case "hu": return "HUF";
                        case "sk": return "EUR";
                        case "ro": return "RON";
                        case "pl": return "PLN";
                        default: return "CZK";
                    }
                };

                const firstStepRegistration = {
                    website: formData.website,
                    phone: phone,
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    country: country,
                    currency: determineCurrency(country),
                    status_create: "billinfo",
                    description: formData.description,
                    pno: formData.pno,
                    budget: formData.budget
                };

                axiosInstance.post('shop/create/', firstStepRegistration)
                    .then(response => {
                        window.dataLayer.push({ event: "register_shop" });
                        sessionStorage.setItem("x23uU09I098D", response.data.pk);
                        setUuid(response.data.pk);
                        navigate(`/${lng}/app/company-details/`);
                    })
                    .catch(error => {
                        setIsUserDataLoading(false)
                        console.error('Error response:', error.response?.data || error.message);
                        if (error.response?.data[0].includes("already exists")) {
                            showAlert(t("registerForm.errorMessages.shopExistsErrorMsg"), "danger");
                        } else {
                            showAlert(t("registerForm.errorMessages.registerShopErrorMsg"), "danger");
                        }
                        sendErrorMessage(userData, selectedShop, error.response?.data || error.message)
                    });
            } else {
                setIsUserDataLoading(false)
                showAlert(t('registerForm.errorMessages.fillAllFieldsErrorMsg'), "danger");
            }
        } catch (error) {
            setIsUserDataLoading(false)
            console.log(error);
            showAlert(t("registerForm.errorMessages.registerShopErrorMsg"), "danger");

        }
    };


    // Přizpůsobení labels v steps
    const localizedSteps = steps.map(step => ({
        ...step,
        label: t(`${step.label}`),
        alertMessage: t(step.alertMessage),
    }));

    const handlePhoneChange = (e) => {
        const { value } = e.target;

        // Povolit pouze číslice a symbol '+'
        const formattedPhone = value.replace(/[^0-9+]/g, '');

        // Pokud je na začátku '+', povolit ho, jinak přidat
        if (formattedPhone[0] !== '+' && formattedPhone.length > 0) {
            e.target.value = formattedPhone; // Nastav hodnotu inputu
        } else {
            e.target.value = formattedPhone;
        }

        setFormData((prevState) => ({
            ...prevState,
            phone: formattedPhone,
        }));
    };

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <title>Registrace obchodu - PermoniQ</title>
            </Helmet>
            {isAlertVisible && (
                <SimpleAlert
                    message={alertMessage.text}
                    onClose={closeAlert}
                    type={alertMessage.type}
                />
            )}
            {/* <div className="progress">
                <div className="container">
                    <ul>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/register-shop/`) : () => showAlert(t("registerForm.contactSupport"), "info")} className="is-active" ><strong>1.</strong> {t('registerProgress.addShop')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/company-details/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>2.</strong> {t('registerProgress.companyDetails')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/upload-products/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>3.</strong> {t('registerProgress.uploadProducts')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/connect-google/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>4.</strong> {t('registerProgress.connectGoogle')}</li>
                        <li onClick={isAdmin ? navigate(`/${lng}/app/install-tracking-code/`) : () => showAlert(t("registerForm.contactSupport"), "info")}><strong>5.</strong> {t('registerProgress.installCode')}</li>
                    </ul>
                </div>
            </div> */}

            <ProgressTracker steps={localizedSteps} />
            <ModalContactUs setMessage={setMessage} />
            {isUserDataLoading && <ModalLoading message={message} />}


            <section className="section register">
                <article className="container">
                    <h1 className="title">{t('registerTitle')}</h1>
                    <form action="#" method="post" autoComplete='off' className="form" onSubmit={handleSubmit}>
                        <div className="box">
                            <p>
                                <label htmlFor="url">{t('registerForm.url')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                <input
                                    type="text"
                                    id="url"
                                    value={formData.website || ''} // Ensure the value is not undefined
                                    onChange={handleInputChange("website")}
                                    onBlur={handleUrlBlur}
                                    required
                                />
                                {!isUrlValid && formData.website && showErrors && <span className="error">{t('registerForm.invalidUrl')}</span>}
                                {showErrors && !formData.website && <span className="error">{t('registerForm.fillAllFields')}</span>}
                            </p>

                            <div className="form__column">
                                <p>
                                    <label htmlFor="phone">{t('registerForm.phone')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        value={formData.phone || ''}
                                        onChange={handleInputChange("phone")}
                                        required
                                        onInput={handlePhoneChange}
                                    />
                                    {/* {showErrors && phoneError && <span className="error">{phoneError}</span>} */}
                                    {showErrors && !formData.phone && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                                <div className="form__p">
                                    <label htmlFor="country">{t('registerForm.country')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <div className={`country ${isCountrySelectOpen ? 'is-open' : ''}`} onClick={toggleCountry}>
                                        <div className="country__selected" data-lang={country}>
                                            {t(`registerForm.countries.${country}`)}
                                        </div>

                                        <ul className="country__switch">
                                            {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                                                <li data-lang={lang} key={lang} onClick={() => handleCountryChange(lang)}>{t(`registerForm.countries.${lang}`)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <select
                                        name="country"
                                        id="country"
                                        className="country__select"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        {['cs', 'sk', 'hu', 'pl', 'ro'].map((lang) => (
                                            <option value={lang} key={lang}>{t(`registerForm.countries.${lang}`)}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="form__column">
                                <p>
                                    <label htmlFor="name">{t('registerForm.name')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={formData.first_name || ''} // Ensure the value is not undefined
                                        onChange={handleInputChange("first_name")}
                                        required
                                    />
                                    {showErrors && !formData.first_name && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                                <p>
                                    <label htmlFor="surname">{t('registerForm.surname')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="text"
                                        id="surname"
                                        value={formData.last_name || ''} // Ensure the value is not undefined
                                        onChange={handleInputChange("last_name")}
                                        required
                                    />
                                    {showErrors && !formData.last_name && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                            </div>
                            {/* <div className="form__column">
                                <p>
                                    <label htmlFor="pno">{t('registerForm.pno')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="number"
                                        id="pno"
                                        value={formData.pno || ''}
                                        onChange={handleInputChange("pno")}
                                        min={5}
                                        max={50}
                                        required
                                    />
                                    {showErrors && !formData.first_name && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                                <p>
                                    <label htmlFor="budget">{t('registerForm.budget')}<sub style={{ color: 'red', fontSize: "1.5rem" }}>*</sub></label>
                                    <input
                                        type="number"
                                        id="budget"
                                        value={formData.budget || ''}
                                        onChange={handleInputChange("last_name")}
                                        required
                                    
                                    />
                                    {showErrors && !formData.last_name && <span className="error">{t('registerForm.fillAllFields')}</span>}
                                </p>
                            </div> */}
                            {/* <div>
                                <label htmlFor="about-us">
                                    {t("registerForm.descAboutShop")}
                                </label>
                                <textarea
                                    id="about-us"
                                    rows="5"
                                    cols="40"
                                    placeholder={t("registerForm.descPlaceholder")}
                                    maxLength={maxLength}
                                    minLength={350}
                                    value={formData.description || ""}
                                    style={{
                                        resize: "none",
                                        borderColor: borderColor,
                                        borderWidth: "2px",
                                    }}
                                    onChange={handleInputChange("description")}
                                />
                                <p style={{textAlign: "right"}}>{charCount}/{maxLength} znaků</p>
                            </div> */}
                            <sub style={{ color: 'red', fontSize: "1.5rem" }}>*<em style={{ color: "black", fontSize: "1rem" }}>{t("requireItem")}</em></sub>
                        </div>

                        <div className="register__submit">
                            <p>
                                <button type="submit" className="btn">{t('registerForm.submit')}</button>
                            </p>
                        </div>
                    </form>
                </article>
            </section>
        </>
    );
};

export default RegisterShop;
