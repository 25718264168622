import React, { useContext, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";


import TermsOfServiceStyles from "../css/TermsOfServiceStyles.module.css"
import { useParams } from "react-router-dom";

const TermsOfServices = () => {
  const { t } = useTranslation();
  const { lng } = useParams()
  const signingProcessSection = t("termsOfService.signingProcessSection", { returnObjects: true });
  const provideServicesSection = t("termsOfService.provideServicesSection", { returnObjects: true });
  const paymentTermsSection = t("termsOfService.paymentTermsSection", { returnObjects: true });
  const serviceAndSupportSection = t("termsOfService.serviceAndSupportSection", { returnObjects: true });
  const rightsAndObligationsSection = t("termsOfService.rightsAndObligationsSection", { returnObjects: true });
  const ourIntellectualPropertiesSection = t("termsOfService.ourIntellectualPropertiesSection", { returnObjects: true });
  const yourAndThirdPartiesIntellectualPropertiesSection = t("termsOfService.yourAndThirdPartiesIntellectualPropertiesSection", { returnObjects: true });
  const contractDurationAndTerminationSection = t("termsOfService.contractDurationAndTerminationSection", { returnObjects: true });
  const responsibilitySection = t("termsOfService.responsibilitySection", { returnObjects: true });
  const howWeProtectConfidentialInformationSection = t("termsOfService.howWeProtectConfidentialInformationSection", { returnObjects: true });
  const finalProvisionsSection = t("termsOfService.finalProvisionsSection", { returnObjects: true });
  const introductoryProvisionsSection = t("processingPersonalData.introductoryProvisionsSection", { returnObjects: true });
  const personalDataSection = t("processingPersonalData.personalDataSection", { returnObjects: true });
  const rightsAndObligationsOfTheContractingPartiesSection = t("processingPersonalData.rightsAndObligationsOfTheContractingPartiesSection", { returnObjects: true });
  const durationOfProcessingAgreementSection = t("processingPersonalData.durationOfProcessingAgreementSection", { returnObjects: true });
  const personalResponsibilitySection = t("processingPersonalData.personalResponsibilitySection", { returnObjects: true });
  const personalFinalProvisionsSection = t("processingPersonalData.personalFinalProvisionsSection", { returnObjects: true });

  const {
    setIsUserDataLoading
  } = useContext(AppContext)

  useEffect(() => {
    setIsUserDataLoading(false)
  })

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>PermoniQ = online reklama na jeden kliQ</title>
      </Helmet>
      <div className="terms-of-service">
        <h1>{t("termsOfService.title")}</h1>
        <section id="about_us_section">
          <p>
            <Trans
              i18nKey={t("termsOfService.aboutUsSection.aboutUs")}
              components={[
                <strong></strong>,
                <a
                  href="https://permoniq.com"
                  className="link">
                </a>
              ]}
            />
          </p>
          <p>
            <Trans
              i18nKey={t("termsOfService.aboutUsSection.aboutTerms")}
              components={[
                <strong></strong>
              ]}
            />
          </p>
          <p style={{ marginBottom: "10px" }}>{t("termsOfService.aboutUsSection.contactOptionsLabel")}</p>
          <ul className={TermsOfServiceStyles.contact_list}>
            <li>{t("termsOfService.aboutUsSection.conntactUsList.address")}</li>
            <li>{t("termsOfService.aboutUsSection.conntactUsList.mail")}</li>
            <li>{t("termsOfService.aboutUsSection.conntactUsList.phone")}</li>
          </ul>
        </section>

        <section id="signing_process">
          <h4>{t("termsOfService.signingProcessSectionTitle")}</h4>
          <ul className={TermsOfServiceStyles.signing_process_list}>
            {Object.entries(signingProcessSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="provide_services">
          <h4>{t("termsOfService.provideServicesSectionTitle")}</h4>
          <ul className={TermsOfServiceStyles.provide_services_list}>
            {Object.entries(provideServicesSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>,
                    <a
                      href={`https://permoniq.com/${lng}/app/settings/`}
                      className="link">
                    </a>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="payment_terms">
          <h4>{t("termsOfService.paymentTermsTitle")}</h4>
          <ul className={TermsOfServiceStyles.payment_terms_list}>
            {Object.entries(paymentTermsSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>,
                    <a
                      href={`https://permoniq.com/${lng}/#comparison/`}
                      className="link">
                    </a>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="service_and_support">
          <h4>{t("termsOfService.serviceAndSupportTitle")}</h4>
          <ul className={TermsOfServiceStyles.service_and_support_list}>
            {Object.entries(serviceAndSupportSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="rights_and_obligation">
          <h4>{t("termsOfService.rightsAndObligationsTitle")}</h4>
          <ul className={TermsOfServiceStyles.rights_and_obligations_list}>
            {Object.entries(rightsAndObligationsSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="intellectual_property">
          <h4>{t("termsOfService.intellectualPropertiesTitle")}</h4>
          <ul className={TermsOfServiceStyles.intellectual_property_list}>
            <p><strong>{t("termsOfService.ourIntellectualProperties")}</strong></p>
            {Object.entries(ourIntellectualPropertiesSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
            <p><strong>{t("termsOfService.yourAndThirdPartiesIntellectualProperties")}</strong></p>
            {Object.entries(yourAndThirdPartiesIntellectualPropertiesSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="contract_duration_and_termination">
          <h4>{t("termsOfService.contractDurationAndTerminationTitle")}</h4>
          <ul className={TermsOfServiceStyles.contract_duration_and_termination_list}>
            {Object.entries(contractDurationAndTerminationSection).map(([key, value], index) => (
              <li key={key}>
                {typeof value === 'object' && !Array.isArray(value) ? (
                  <div>
                    <Trans
                      i18nKey={value.mainText}
                      components={[<strong></strong>]}
                    />
                    <ul className={TermsOfServiceStyles.subpoints}>
                      {Object.entries(value.points).map(([subKey, subValue], subIndex) => (
                        <li key={subKey}>
                          <span className={TermsOfServiceStyles.subpoint_letter}>{`${String.fromCharCode(97 + subIndex)})`}</span>
                          <Trans
                            i18nKey={subValue}
                            components={[<strong></strong>]}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Trans
                    i18nKey={value}
                    components={[<strong></strong>]}
                  />
                )}
              </li>
            ))}
          </ul>
        </section>
        <section id="responsibility">
          <h4>{t("termsOfService.responsibilityTitle")}</h4>
          <ul className={TermsOfServiceStyles.responsibility_list}>
            {Object.entries(responsibilitySection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="how_we_protect_information">
          <h4>{t("termsOfService.howWeProtectConfidentialInformationTitle")}</h4>
          <ul className={TermsOfServiceStyles.how_we_protect_information_list}>
            {Object.entries(howWeProtectConfidentialInformationSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="final_provisions">
          <h4>{t("termsOfService.finalProvisionsTitle")}</h4>
          <ul className={TermsOfServiceStyles.final_provisions_list}>
            {Object.entries(finalProvisionsSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>,
                    <a
                      href={`https://permoniq.com/${lng}/privacy-policy/`}
                      className="link">
                    </a>
                  ]}
                />
              </li>
            ))}
          </ul>
          <p>{t("termsOfService.duration")}</p>
        </section>
      </div>
      <div className="processing_personal_data">
        <h3>{t("processingPersonalData.annexNumber")}</h3>
        <h2>{t("processingPersonalData.title")}</h2>
        <p style={{ textAlign: "center" }}>
          <Trans
            i18nKey={t("processingPersonalData.gdpr")}
            components={[
              <strong></strong>
            ]}
          />
        </p>
        <section id="introductory_provisions">
          <h4>{t("processingPersonalData.introductoryProvisionsTitle")}</h4>
          <ul className={TermsOfServiceStyles.introductory_provisions_list}>
            {Object.entries(introductoryProvisionsSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="personal_data">
          <h4>{t("processingPersonalData.personalDataTitle")}</h4>
          <ul className={TermsOfServiceStyles.personal_data_list}>
            {Object.entries(personalDataSection).map(([key, value], index) => (
              <li key={key}>
                {typeof value === 'object' && !Array.isArray(value) ? (
                  <div>
                    <Trans
                      i18nKey={value.mainText}
                      components={[<strong></strong>]}
                    />
                    <ul className={TermsOfServiceStyles.subpoints}>
                      {Object.entries(value.points).map(([subKey, subValue], subIndex) => (
                        <li key={subKey}>
                          <span className={TermsOfServiceStyles.subpoint_letter}>{`${String.fromCharCode(97 + subIndex)})`}</span>
                          <Trans
                            i18nKey={subValue}
                            components={[<strong></strong>]}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Trans
                    i18nKey={value}
                    components={[<strong></strong>]}
                  />
                )}
              </li>
            ))}
          </ul>
        </section>
        <section id="rights_and_obligations_contracting_parties">
          <h4>{t("processingPersonalData.rightsAndObligationsOfTheContractingPartiesTitle")}</h4>
          <ul className={TermsOfServiceStyles.rights_and_obligations_contracting_parties_list}>
            {Object.entries(rightsAndObligationsOfTheContractingPartiesSection).map(([key, value], index) => (
              <li key={key}>
                {typeof value === 'object' && !Array.isArray(value) ? (
                  <div>
                    <Trans
                      i18nKey={value.mainText}
                      components={[<strong></strong>]}
                    />
                    <ul className={TermsOfServiceStyles.subpoints}>
                      {Object.entries(value.points).map(([subKey, subValue], subIndex) => (
                        <li key={subKey}>
                          <span className={TermsOfServiceStyles.subpoint_letter}>{`${String.fromCharCode(97 + subIndex)})`}</span>
                          <Trans
                            i18nKey={subValue}
                            components={[<strong></strong>]}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Trans
                    i18nKey={value}
                    components={[<strong></strong>]}
                  />
                )}
              </li>
            ))}
          </ul>
        </section>
        <section id="duration_processing_agreement">
          <h4>{t("processingPersonalData.durationOfProcessingAgreementTitle")}</h4>
          <ul className={TermsOfServiceStyles.duration_processing_agreement_list}>
            {Object.entries(durationOfProcessingAgreementSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="personal_responsibility">
          <h4>{t("processingPersonalData.personalResponsibilityTitle")}</h4>
          <ul className={TermsOfServiceStyles.personal_responsibility_list}>
            {Object.entries(personalResponsibilitySection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="personal_final_provisions">
          <h4>{t("processingPersonalData.personalFinalProvisionsTitle")}</h4>
          <ul className={TermsOfServiceStyles.personal_final_provisions_list}>
            {Object.entries(personalFinalProvisionsSection).map(([key, value], index) => (
              <li key={key}>
                <Trans
                  i18nKey={value}
                  components={[
                    <strong></strong>
                  ]}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </>
  );
};

export default TermsOfServices;
